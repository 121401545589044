<script setup lang="ts">
import FooterLogo from '~/assets/img/footer-logo.svg'; // Импорт логотипа футера
import {useUserProfileStore} from "~/store/user/user_prifile.store"; // Импорт хранилища профиля пользователя

// Получение состояний из Pinia stores
const authStore = useAuthStore(); // Хранилище для авторизации
const userProfile = useUserProfileStore(); // Хранилище профиля пользователя
const statesStore = useStatesStore(); // Хранилище для управления состояниями
const userStatesStore = useUserStatesStore(); // Хранилище для управления пользовательскими состояниями
const Loader = useIsLoadingStore()

const config = useRuntimeConfig()

// Функция выхода из аккаунта
function logout() {
  authStore.clear(); // Очистка данных авторизации
  statesStore.useClearCustomStates(); // Очистка пользовательских состояний
  // userStatesStore.useClearUserStates(); // Очистка других пользовательских состояний
  userProfile.useClearUserProfile(); // Очистка профиля пользователя
  navigateTo('/'); // Перенаправление на главную страницу
}
</script>

<template>
  <footer class="ys-footer py-8 font-medium mt-10">
    <div class="container">
      <!-- Логотип футера -->
      <div class="mb-8">
        <NuxtLink to="/" class="cursor-pointer block w-fit">
          <FooterLogo class="ys-logo"/>
        </NuxtLink>
      </div>
      <!-- Основная навигация в футере -->
      <div class="flex justify-between items-start pb-4 border-b border-gray-200">
        <nav class="flex xl:space-x-36 sm:space-x-8">
          <ul class="font-medium lg:columns-3 gap-20">
            <li class="mb-2">
                <!-- Элемент меню, если пользователь не авторизован -->
                <LayoutsAuthMainElement :icon="false" class="font-medium" v-if="!authStore.isAuth"/>

                <!-- Кнопка выхода из аккаунта, если пользователь авторизован -->
                <UiButton :disabled="Loader.isLoading" class="ys-button" v-if="authStore.isAuth" @click="logout">
                  <Icon v-if="Loader.isLoading" name="svg-spinners:ring-resize" />
                  <span v-else>{{ $t("Logout") }}</span>
                </UiButton>
            </li>
            <li class="mb-2">
                <template v-if="authStore.isAuth">
                    <!-- Ссылка для создания заказа, доступная пользователю с ролью 'CUSTOMER' -->
                    <nuxt-link 
                        v-if="userProfile.Role === 'CUSTOMER' "
                        :to="{ name: 'order-create' }"
                        class="hover:text-gray-400">
                        <p>{{ $t("Create task") }}</p>
                    </nuxt-link>

                    <!-- Ссылка для поиска задач, доступная пользователю с ролью 'MASTER' -->
                    <nuxt-link 
                        v-if="authStore.isAuth && userProfile.Role === 'MASTER' "
                        :to="{ name: 'master-office-task-search' }"
                        class="hover:text-gray-400">
                        <p>{{ $t("Tasks search") }}</p>
                    </nuxt-link>
                </template>
                <template v-else>
                    <!-- Элемент меню, если пользователь не авторизован -->
                    <LayoutsAuthMainElement
                        :icon="false" :className="'ys-button hover:text-gray-400'"
                        :title="$t('Create task')"
                    />
                </template>
            </li>
            <li class="mb-2">
                <NuxtLink to="/help" class="hover:text-gray-600">{{ $t("Help & Support") }}</NuxtLink>
            </li>
            <li class="mb-2">
                <NuxtLink to="/aboutus" class="hover:text-gray-600">{{ $t("About us") }}</NuxtLink>
            </li>
            <li class="mb-2">
                <NuxtLink to="/contacts" class="hover:text-gray-600">{{ $t("Contacts") }}</NuxtLink>
            </li>
          </ul>
        </nav>

        <!-- Иконки приложений для мобильных устройств -->
        <div v-if="config.public.playstoreLink || config.public.appstoreLink" class="flex justify-between my-auto">
            <a :href="config.public.playstoreLink" target="_blank">
                <NuxtImg class="ys-mobile-logo" src="/home_page/googleplay.svg" />
            </a>
            <a :href="config.public.appstoreLink" target="_blank" class="ltr:ml-6 rtl:mr-6">
                <NuxtImg class="ys-mobile-logo" src="/home_page/appstore.svg" />
            </a>
        </div>

      </div>

      <!-- Навигация в нижней части футера -->
      <div class="flex justify-between items-start">
        <nav class="pt-7 pb-5">
          <ul class="flex space-x-8">
            <!-- Кнопка выбора местоположения и другие элементы навигации -->
            <CustomUiButtonLocationSelect btn_class="max-h-[36px] px-2 text-main" />
            <LayoutsLanguageSelector class="lg-selector-for-footer"/>

            <!-- Ссылки на политику конфиденциальности и условия обслуживания -->
            <NuxtLink class="my-auto hover:text-gray-400 cursor-pointer"
                      :to="{ name: 'privacy-policy' }">{{ $t("Privacy Policy") }}
            </NuxtLink>
            <NuxtLink class="my-auto hover:text-gray-400 cursor-pointer"
                      :to="{ name: 'terms-of-service' }">{{ $t("Terms of Service") }}
            </NuxtLink>
          </ul>
        </nav>
      </div>
      <!-- Текст с копирайтом -->
      <div class="text-gray-400">
        © {{ new Date().getFullYear() }} YourService
      </div>
    </div>
  </footer>
</template>

<style scoped lang="scss">
.ys-mobile-logo {
  width: 125.62px;
  height: 35px;
  box-shadow: 0 1px 2px 0 var(--ys-button-shadow-inset) inset;
  border-radius: 6px;
}


.ys-footer-nav-button {
  @apply my-auto cursor-pointer;
  color: rgb(0, 0, 0);
  background: unset;
  text-decoration: unset;
  border-radius: 8px;
  font-size: 14px;
  height: 36px;
  padding: 8px 12px 8px 12px;
  gap: 8px;
}

.ys-footer-nav-button:hover {
  background: unset;
}

</style>



<script lang="ts" setup>
import { createReusableTemplate, useMediaQuery } from '@vueuse/core'
import { ref, computed } from 'vue'
import { Button } from '@/components/ui/button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '@/components/ui/command'
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import type { City } from '~/types/dictionary'

const { locations, cities } = useDictionaryStore()

const props = defineProps({
  btn_class: {
    type: String,
    default: null
  },
})

const { locale } = useI18n()

const [UseTemplate, LocationsList] = createReusableTemplate()
const isDesktop = useMediaQuery('(min-width: 768px)')
const isOpen = ref(false)

const userProfile = useUserProfileStore();
const selectedCity = computed(() => cities.find(item => item.id === userProfile.Region))

function onLocationSelect(city: City) {
    userProfile.setUserLocation(city.id)
    isOpen.value = false
}

</script>

<template>
  <div>
    <UseTemplate>
      <Command :dir="locale === 'ar' ? 'rtl' : 'ltr'">
        <CommandInput ::placeholder="$t('Filter locations...')"  />
        <CommandList>
          <CommandEmpty>{{$t('No results found')}}</CommandEmpty>
          <CommandGroup v-for="location in locations" :heading="location.name">
            <CommandItem
              v-for="city in location.cities"
              :value="city.name"
              @select="onLocationSelect(city)"
            >
              {{ city.name }}
            </CommandItem>
          </CommandGroup>
        </CommandList>
      </Command>
    </UseTemplate>

    <Popover v-if="isDesktop" v-model:open="isOpen">
      <PopoverTrigger as-child>
        <Button variant="ghost" class="w-fit justify-start border-0 px-2" :class="props.btn_class || 'max-h-[36px] px-2'">
            <Icon size="20" name="ri:map-pin-2-line" class="mr-2 rtl:ml-2" :class="{'text-primary-500':isOpen}" />
            {{ selectedCity ? selectedCity.name : $t('Select location') }}
            <Icon size="18" name="ri:arrow-down-s-line" class="ml-2 rtl:mr-2 shrink-0 opacity-50"/>
        </Button>
      </PopoverTrigger>
      <PopoverContent class="w-[200px] p-0" align="start">
        <LocationsList />
      </PopoverContent>
    </Popover>

    <Drawer v-else :open="isOpen" @update:open="(newOpenValue) => isOpen = newOpenValue">
      <DrawerTrigger as-child>
        <Button variant="ghost" class="w-fit justify-start border-0 px-2" :class="props.btn_class || 'max-h-[36px] px-2'">
            <Icon size="20" name="ri:map-pin-2-line" class="mr-2 rtl:ml-2" :class="{'text-primary-500':isOpen}" />
            {{ selectedCity ? selectedCity.name : $t('Select location') }}
            <Icon size="18" name="ri:arrow-down-s-line" class="ml-2 rtl:mr-2 shrink-0 opacity-50"/>
        </Button>
      </DrawerTrigger>
      <DrawerContent>
        <div class="mt-4 border-t">
          <LocationsList />
        </div>
      </DrawerContent>
    </Drawer>
  </div>
</template>